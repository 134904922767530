import { Box, Container, Grid, Group, Image, rem, Stack, Text, Title } from '@mantine/core';

import Accessibility from './Accessibility.svg';
import Leaf from './Leaf.svg';
import SpeedometerLow from './SpeedometerLow.svg';
import classes from './Why.module.css';
import image from './WhyInvest.png';

export default function WhyInvest(props) {
  return (
    <Container {...props}>
      <Grid grow py={{ base: 50, md: 100 }}>
        <Grid.Col span={{ md: 7 }}>
          <div>
            <Title className={classes.title} ta={{ base: 'center', xs: 'left' }} maw={{ base: '80%', xs: '100%' }} m={'0 auto'}>
              Why Invest in Islamic Art
            </Title>
            <Text mt="md" className={classes.subtitle}>
              Be among the pioneers with Goldframer, the first digital platform to unlock the world of Islamic art investment.
              Islamic art is a gem that awaits its deserved recognition. Be among the first to buy digitally Islamic artwork
              shares. Start your legacy with us today.
            </Text>

            <Grid mt={'xl'}>
              <Grid.Col span={{ md: 6 }}>
                <Group wrap={'nowrap'} align={'stretch'} justify={'flex-start'}>
                  <Box pt={5}>
                    <Image src={SpeedometerLow} w={48} />
                  </Box>
                  <Stack>
                    <Text c="gfdark.9" fz={rem(20)}>
                      Untapped Potential
                    </Text>
                    <Text c="gfdark.5" fz={rem(16)}>
                      Islamic art is a gem that awaits its deserved recognition.
                    </Text>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ md: 6 }}>
                <Group wrap={'nowrap'} align={'stretch'}>
                  <Box pt={5}>
                    <Image src={Leaf} w={48} />
                  </Box>
                  <Stack>
                    <Text c="gfdark.9" fz={rem(20)}>
                      Ethical Art Investing
                    </Text>
                    <Text c="gfdark.5" fz={rem(16)}>
                      Be at peace knowing your art investments are halal and contribute to preserving Islamic heritage.
                    </Text>
                  </Stack>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ md: 6 }}>
                <Group wrap={'nowrap'} align={'stretch'} mt={'sm'}>
                  <Box pt={5}>
                    <Image src={Accessibility} w={48} />
                  </Box>
                  <Stack>
                    <Text c="gfdark.9" fz={rem(20)}>
                      Market Accessibility
                    </Text>
                    <Text c="gfdark.5" fz={rem(16)}>
                      We bring down the barriers, making art investment as simple as a few clicks.
                    </Text>
                  </Stack>
                </Group>
              </Grid.Col>
            </Grid>
          </div>
        </Grid.Col>
        <Grid.Col span={{ md: 5 }}>
          <Image src={image} className={classes.image} m={'0 auto'} maw={{ base: 350, md: '100%' }} />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
